<template>
<div class="examin" v-loading="pageLoading">
      
      <div class="des" v-show="examinResult==1">
        <img class="pass" :src="passImg" v-if="isPass"/>
        <img class="noPass" :src="noPassImg" v-else/>
        <div class="examinText" :class="{red:!isPass}">{{isPass ? '通过' : '未通过'}}</div>
        <p class="text1">您本次考试成绩为：{{score}}分</p>
        <p class="text2">姓名：{{name}}<span></span>提交时间：{{time}}</p>
        <p class="btn" @click="examinResult=2">查看最终考试结果</p>
      </div>
      <div class="des" v-show="examinResult==2">
        <img class="pass" :src="passImg" v-if="isMaxPass"/>
        <img class="noPass" :src="noPassImg" v-else/>
        <div class="examinText" :class="{red:!isPass}">{{isMaxPass ? '通过' : '未通过'}}</div>
        <p class="text1">您最终考试成绩为：{{maxScore}}分</p>
        <p class="text2">备注：考试试卷共有三次答题机会，取其中的最高分作为最终成绩</p>
      </div>
      
</div>
</template>

<script>
export default {
  name: "lesson",
  data() {
    return {
      id:'',
      passImg:'https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/pass.png',
      noPassImg:'https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/noPass.png',
      pageLoading: false,
      examinResult:1,//1当前考试成绩，2最终考试成绩

      score: 0,
      time:'',
      maxScore:0,
      isMaxPass:true,
      isPass:true,
      name:'',
    };
  },
  async mounted() {
    this.examinResult = this.$route.query.examinResult ? this.$route.query.examinResult : 1
    this.name = this.$store.state.user.nickname || this.$store.state.user.name
    this.score = this.$route.query.score
    this.time = this.$route.query.time
    this.isPass = this.$route.query.is_pass == 1 ? true :false
    this.maxScore = this.$route.query.maxScore
    this.isMaxPass = this.$route.query.is_max_pass == 1 ? true :false
    this.finished = this.$route.query.finished == 'true'
   
  },
  destroyed() {
   
  },
  computed: {
   
  },
  methods: {
   
    toDetail(){
      this.$router.push({path:"./examinResultDetail?id="+this.id+"&examinId="+this.$route.query.examinId})
    },
  },
  beforeRouteUpdate(to, from, next) {
    console.log("beforeRouteUpdate");
    console.log(to);
    next();
  },
};
</script>

<style lang="scss" scoped>
.examin {
  min-height: calc(100vh - 330px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #333333;
  width:1200px;
  background: #f6f9fc;
  border-radius: 8px;
  margin: 20px auto;
  text-align: center;
  .pass{
    width: 360px;
  }
  .noPass{
    width: 260px;
  }
  .examinText{
    font-size: 22px;
    color: #333333;
  }
  .red{
    color: #E9313F;
  }
  .text1{
    color: #333;
    font-size: 18px;
    margin: 10px auto 20px;
  }
  .text2{
    height: 43px;
    border-radius: 10px;
    line-height: 43px;
    background: #edf0f2;
    font-size: 14px;
    color: #666666;
    text-align: center;
    padding: 0 20px;
    span{
      padding-left: 30px;
    }
  }
  .btn{
    margin: 30px auto 0;
    width: 170px;
    height: 40px;
    border-radius: 4px;
    text-align: center;
    line-height: 40px;
    background: #2e66d0;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
  }
}
</style>

<style lang="scss">
</style>
